<style scoped></style>

<template>
  <div>
    <nullPage title="未购买授权" />
  </div>
</template>

<script>
import nullPage from './nullPage.vue'
export default {
  components: { nullPage }
}
</script>
